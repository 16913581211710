import $ from 'jquery';

window.cafe = window.cafe || {};

(function () {
	cafe.util = cafe.util || {};

	cafe.util.isStorageAvailable = storage => {
		if (typeof storage === 'undefined') {
			return false;
		}

		try {
			storage.setItem('storage', '');
			storage.getItem('storage');
			storage.removeItem('storage');
			return true;
		} catch (err) {
			return false;
		}
	};

	cafe.util.historyBack = () => {
		history.back();
	};

	cafe.util.locationAssign = url => {
		location.assign(url);
	};

	cafe.util.prevLocation = defaultUrl => {
		if (history.length > 1) {
			cafe.util.historyBack();
			return;
		}
		cafe.util.locationAssign(defaultUrl);
	};

	cafe.util.getCookie = name => {
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookie = decodedCookie.split(';')
			.map(v => v.trim().split('='))
			.find(v => v[0] === name);

		return cookie ? cookie[1] : '';
	};

	cafe.util.deleteCookie = name => {
			document.cookie = name + '=' + '; path=/; domain=daum.net' + '; expires=Thu, 01-Jan-70 00:00:01 GMT';
	};
	
	cafe.util.isDaumApp = () => {
		return cafe.util.getCookie('daumGlueApp');
	};
	
	cafe.util.toLocaleString = number => {
		if (!number || typeof number !== 'number') {
			return '0';
		}

		return number.toLocaleString();
	};

	cafe.util.scrollToCenter = element => {
		if(element === undefined)
			return;

		const wrapElement = $(element);
		const deviceHeight = $(window).height();

		$(document).scrollTop(Math.max(0, wrapElement.offset().top - deviceHeight / 2 + wrapElement.height() / 2));
	};

	(function () {
		const match = navigator.userAgent.match(/Android\s([0-9.]*)/);
		const androidVersion = match ? match[1] : 'unknown';
		const androidVersionLower4_4 = !(androidVersion === 'unknown' || parseFloat(androidVersion) >= 4.4);

		cafe.util.canUseHistory = function () {
			return !!(!androidVersionLower4_4 && history && history.replaceState);
		};

		cafe.util.androidVersionLowerThan = function (version) {
			if (androidVersion === 'unknown') {
				return false;
			}
			return parseFloat(androidVersion) < version;
		};
	})();
})();
